/* eslint-disable array-callback-return */
//import * as XLSX from 'js-xlsx-map';
import * as XLSX from 'xlsx'

const getDataType = (value, type) => {
	switch (type) {
		case 'string':
			return typeof value === 'string'
		case 'number':
			return !isNaN(Number(value))
		case 'date':
			return new Date(value).toString() !== 'Invalid Date'
		default:
			return true
	}
}

export const validateExcelBot = ({
	schema,
	file,
	fieldList,
	onSuccess,
	finalFormList,
	onError,
	// toggleLoader,
}) => {
	if (!Array.isArray(schema) || !schema?.length) {
		onSuccess(file, [])
		return false
	}
	const fieldId = schema[0].field_id
	let comboId
	let sheet_name = !!schema[0].sheet_name ? schema[0].sheet_name : false

	if (
		!!finalFormList &&
		!!finalFormList.list &&
		finalFormList.list.length > 0
	) {
		let dependentField = (finalFormList.list || []).find(item => {
			return fieldId === item.field_id
		})
		comboId = dependentField.data_type_combo_id
	} else {
		comboId =
			!!fieldList[fieldId] &&
			!!fieldList[fieldId].final &&
			fieldList[fieldId]?.final?.data_type_combo_id
	}

	const validationCell = schema.find(valItem => {
		if (
			valItem.field_id === fieldId &&
			valItem.data_type_combo_id === comboId
		) {
			return valItem
		}
	})
	if (
		schema.length > 0 &&
		!!validationCell &&
		Object.keys(validationCell).length > 0
	) {
		// toggleLoader(true);
		const validationCellData = validationCell.validation_cells
		const fileReader = new FileReader()
		try {
			fileReader.onload = function (e) {
				let isValid = true
				let cellError
				let errorArr = []
				let data = e.target.result
				try {
					let workbook = XLSX.read(data, { type: 'binary', bookVBA: true })
					const sheetName = workbook.SheetNames[0]
					const sheetData = workbook.Sheets[sheetName]
					let excelRowsObjArr = XLSX.utils.sheet_to_row_object_array(sheetData)
					if (!!sheet_name) {
						if (sheetName !== sheet_name) {
							onError({
								cell_value: 'Excel sheet name mismatch - ' + sheet_name,
							})
							return false
						}
					}
					;(validationCellData || []).forEach(cell => {
						const cellKey = `${cell.cell_x}${cell.cell_y}`.toUpperCase()
						const { v } = sheetData[cellKey] || {}
						if (cell && cell.cell_value && !!v) {
							let cellData = excelRowsObjArr.map(s => {
								return {
									column: v,
									is_mandatory: cell.is_mandatory,
									column_datatype: cell.column_datatype,
									cellKey,
									data: s[v],
									isDataValid:
										!!cell.is_mandatory && cell.is_mandatory === 1
											? !s[v]
												? false
												: !!cell.column_datatype
												? getDataType(s[v], cell.column_datatype)
												: true
											: true,
								}
							})
							let isDValid = cellData.every(d => d.isDataValid === true)
							let cellValue = typeof v === 'string' ? v.trim() : v
							if (cellValue !== cell.cell_value.trim() || !isDValid) {
								isValid = false
								errorArr.push(cell)
							}
						} else if (!v) {
							isValid = false
							errorArr.push(cell)
							// console.log(isValid);
							// return isValid;
							// onError(cell);
						}
						if (!isValid) {
							// toggleLoader(false);
							cellError = cell
							errorArr.push(cell)
						}
					})
					if (isValid) {
						// toggleLoader(false);
						onSuccess(file, [])
					} else {
						onError(errorArr[0])
					}
				} catch (err) {
					console.log(err)
					onError(err)
				}
			}
		} catch (err) {
			console.log(err)
			onError(err)
		}
		fileReader.readAsBinaryString(file)
	} else if (!comboId && schema[0].field_id === 0) {
		const validationCellData = schema[0].validation_cells
		const fileReader = new FileReader()
		try {
			fileReader.onload = function (e) {
				let isValid = true
				let data = e.target.result
				let errorCell = {}
				let errorArr = []
				try {
					let workbook = XLSX.read(data, { type: 'binary', bookVBA: true })
					const sheetName = workbook.SheetNames[0]
					const sheetData = workbook.Sheets[sheetName]
					let excelRowsObjArr = XLSX.utils.sheet_to_row_object_array(sheetData)
					if (!!sheet_name) {
						if (sheetName !== sheet_name) {
							onError({
								cell_value: 'Excel sheet name mismatch - ' + sheet_name,
							})
							return false
						}
					}
					;(validationCellData || []).forEach(cell => {
						const cellKey = `${cell.cell_x}${cell.cell_y}`.toUpperCase()
						const { v } = sheetData[cellKey] || {}
						if (cell && cell.cell_value && !!v) {
							let cellData = excelRowsObjArr.map(s => {
								return {
									column: v,
									is_mandatory: cell.is_mandatory,
									column_datatype: cell.column_datatype,
									cellKey,
									data: s[v],
									isDataValid:
										!!cell.is_mandatory && cell.is_mandatory === 1
											? !s[v]
												? false
												: !!cell.column_datatype
												? getDataType(s[v], cell.column_datatype)
												: true
											: true,
								}
							})
							let isDValid = cellData.every(d => d.isDataValid === true)
							let cellValue = typeof v === 'string' ? v.trim() : v
							if (cellValue !== cell.cell_value.trim() || !isDValid) {
								isValid = false
								errorCell = cell
								errorArr.push(cell)
							}
						}
					})
					if (isValid) {
						// toggleLoader(false);
						console.log('success')
						onSuccess(file, [])
					} else {
						// toggleLoader(false);
						console.log('error', errorCell, errorArr)
						onError(errorArr[0])
					}
				} catch (err) {
					console.log(err)
					onError(err)
				}
			}
		} catch (err) {
			console.log(err)
			onError(err)
		}
		fileReader.readAsBinaryString(file)
	} else if (!comboId && schema[0].field_id !== 0) {
		onError()
	} else {
		onSuccess(file, [])
	}
	// return isValid
}

const getFileType = file => {
	const fileType = file.type ? file.type.toLowerCase() : ''
	if (
		!![
			'xlsx',
			'xlsm',
			'xlsb',
			'xltx',
			'xltm',
			'xls',
			'csv',
			'xlc',
			'sheet',
			'application/vnd.ms-excel',
		].find(item => fileType.includes(item))
	) {
		return 'sheet'
	}
	return 'attach'
}

export const dropBoxValidateExcelBot = ({
	schema,
	file,
	fieldList,
	onSuccess,
	finalFormList,
	onError,
	toggleLoader,
}) => {
	let fileType = getFileType(file)
	// Return if no schema found to validate
	if (!schema) {
		onSuccess(file, [])
		return
	}
	if (fileType === 'attach') {
		onSuccess(file, [])
		return
	}
	let isOR,
		isAND,
		isNOR = false
	let multi_condition = schema.multi_condition
	if (!!multi_condition) {
		isOR = multi_condition.includes('+')
		isAND = multi_condition.includes('*')
		isNOR = multi_condition.includes('!')
	}

	if (
		schema.validation_cells &&
		Object.keys(schema.validation_cells).length > 0
	) {
		toggleLoader(true)
		const validationCellData = Object.keys(schema.validation_cells)
		const fileReader = new FileReader()
		try {
			fileReader.onload = function (e) {
				let isValid = true
				let validateResults = []
				let data = e.target.result
				let workbook = XLSX.read(data, { type: 'binary', bookVBA: true })
				;(validationCellData || []).forEach(i => {
					let kkey = 'a' + i
					let object = {}
					let valid_cells = true
					;(schema.validation_cells[i] || []).forEach(cell => {
						const sheetName = workbook.SheetNames[0]
						const sheetData = workbook.Sheets[sheetName]
						const cellKey = `${cell.cell_x}${cell.cell_y}`.toUpperCase()
						const { v } = sheetData[cellKey] || {}
						if (cell && cell.cell_value && !!v) {
							let cellValue = typeof v === 'string' ? v.trim() : v
							if (cellValue !== cell.cell_value.trim()) {
								valid_cells = false
							} else {
								valid_cells = true
							}
						} else if (!v) {
							valid_cells = false
						}
					})
					object['value'] = valid_cells
					validateResults.push(object)
				})

				let results = true
				if (isOR) {
					results = validateResults.findIndex(f => f.value === true) !== -1
				}
				if (isAND) {
					results = validateResults.findIndex(f => f.value === false) !== -1
				}
				let final_results = results
				if (isNOR) {
					final_results = !results
				}
				if (final_results) {
					toggleLoader(false)
					onSuccess(file, [])
				} else {
					toggleLoader(false)
					onError()
				}
			}
		} catch (err) {
			toggleLoader(false)
		}
		fileReader.readAsBinaryString(file)
	} else {
		onSuccess(file, [])
	}
}
