import React, { useRef, useState } from 'react'
import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	List,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react'
import { FiUploadCloud } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import {
	GA_CATEGORY_DIALOG_BOX,
	sectionDetailedTrack,
} from '../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
//   import { useAuthContext } from '../../../../context/auth.context';
//   import { useSharedContext } from '../../../../context/shared.context';
//   import { useAddTimelineUpdate } from '../../../../hooks/activity.hooks';
//   import { sharedConst } from '../../../../utils/action.constant';
//   import { sanitizeContent } from '../../../../utils/common.util';
//   import { formatNoteUpdateDate } from '../../../../utils/date.utils';

const AttachmentModal = ({ activity, isOpen, onClose, onUpload }) => {
	const inputRef = useRef()
	const [isError, setisError] = useState(false)
	const [fileList, setfileList] = useState([])
	const [uploadingFile, setuploadingFile] = useState(null)
	const {
		state: { locale },
	} = useLanguageContext()

	const onHandleFileChange = event => {
		setisError(false)
		const files = [...event.target.files].filter(file => {
			if (file.name.split('.').length > 2) {
				return true
			}
			return true
		})
		if (files.length === 0) {
			setisError(true)
		} else if (
			event.target.files[0].name.split('.').pop() === 'exe' ||
			event.target.files[0].name.split('.').pop() === 'php' ||
			event.target.files[0].name.split('.').pop() === 'sh' ||
			event.target.files[0].name.split('.').pop() === 'py'
		) {
			setisError(true)
		} else {
			setfileList([...fileList, ...files])
		}
	}

	const removeFile = file => {
		let files = fileList.filter(
			f => f.name.toLowerCase() !== file.name.toLowerCase()
		)
		setfileList(files)
	}

	const fileSizeFormatted = size => {
		if (!size || size < 1000) {
			return `${size} Bytes`
		} else if (size < 1000000) {
			return `${Math.floor(size / 1000)}KB`
		} else {
			return `${Math.floor(size / 1000000)}MB`
		}
	}
	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent borderRadius='md' bg='white' minW='70vw' h='700px'>
				<ModalCloseButton />
				<ModalBody p={0} w='100%'>
					<HStack h='full'>
						<Box h='full' w='40%'>
							<VStack h='full' justifyContent='center' alignItems='center'>
								<Heading
									textTransform='uppercase'
									size='lg'
									color='brand.800'
									fontWeight='400'
									mb={3}
								>
									{locale['File Upload']}
								</Heading>
								<Flex
									w='80%'
									h='60%'
									border='2px'
									borderColor='brand.800'
									borderStyle='dashed'
									borderRadius='10px'
									py={2}
								>
									<VStack
										justifyContent='center'
										w='100%'
										alignItems='center'
										spacing={3}
									>
										<Icon
											as={FiUploadCloud}
											w={20}
											h={20}
											color='brand.800'
											strokeWidth='1px'
										/>
										<Text color='brand.800' fontSize='18px'>
											{locale['Drag and Drop Files']}
										</Text>
										<Text color='brand.800' fontSize='18px' textAlign='center'>
											{locale['OR']}
										</Text>
										<input
											type='file'
											// accept={acceptedFileTypes}
											name='file'
											onChange={onHandleFileChange}
											ref={inputRef}
											style={{ display: 'none' }}
										></input>
										<Button
											borderRadius='md'
											boxShadow='md'
											size='md'
											variant='solid'
											colorScheme={localStorage.getItem('color')}
											bg={localStorage.getItem('color')}
											fontSize='14px'
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_DIALOG_BOX,
													action: 'Attachment Modal',
													label: 'Browse Files',
												})
												inputRef.current.click()
											}}
										>
											{locale['BROWSE FILES']}
										</Button>
										<Text color='brand.800' fontSize='10px' textAlign='center'>
											{locale['Supports']} :
											<Text
												display='block'
												as='span'
												color='brand.800'
												fontSize='10px'
												textAlign='center'
												textTransform='capitalize'
											>
												JPG, PDF, PNG, XLX, XLSX, XLSB, Doc, Docx, Docs, ppt,
												mov, mp4
											</Text>
										</Text>
										{isError && (
											<Text
												color='red'
												fontSize='12px'
												textAlign='center'
												px={5}
											>
												{locale['File format not supported']} <br />{' '}
												{locale['or']} <br />{' '}
												{locale['File name should not contain multiple (dots)']}
											</Text>
										)}
									</VStack>
								</Flex>
							</VStack>
						</Box>
						<VStack
							h='100%'
							borderRightRadius='md'
							flex={1}
							bg='secondary'
							display='flex'
						>
							<VStack w='80%' mx='auto' flex={1} mt={10} alignItems='center'>
								<List w='full' spacing={3} h='550px' overflowY='scroll'>
									{(fileList || []).map((file, index) => {
										const { name, size } = file
										return (
											<>
												<ListItem>
													<Flex spacing={2}>
														<Center position='relative' alignSelf='center'>
															<Box
																boxShadow='md'
																bg='white'
																borderRadius='full'
																p={3}
															>
																<Text fontSize='12px' textTransform='uppercase'>
																	{name.split('.')[1]}
																</Text>
															</Box>
														</Center>
														<Box ml={2} flex={1}>
															<Text color='brand.800' fontSize='14px'>
																{name}
															</Text>
															<Text color='brand.800' fontSize='10px'>
																{fileSizeFormatted(size)}
															</Text>
														</Box>
														{!!uploadingFile &&
														uploadingFile.name === file.name ? (
															<Spinner color={localStorage.getItem('color')} />
														) : (
															<IconButton
																bg='white'
																borderRadius='md'
																boxShadow='md'
																aria-label='More'
																size='sm'
																_focus={{
																	bg: 'secondary',
																}}
																_hover={{
																	bg: 'secondary',
																}}
																icon={<Icon as={MdClose} w={6} h={6} />}
																onClick={() => {
																	sectionDetailedTrack({
																		category: GA_CATEGORY_DIALOG_BOX,
																		action: 'Attachment Modal',
																		label: 'Remove File',
																	})
																	removeFile(file)
																}}
															/>
														)}
													</Flex>
												</ListItem>
												<Divider border='1px' borderColor='white' />
											</>
										)
									})}
								</List>
							</VStack>
							<ModalFooter
								w='full'
								py={2}
								alignSelf='flex-end'
								justifySelf='flex-end'
							>
								<Button
									variant='outline'
									colorScheme={localStorage.getItem('color')}
									mr={3}
									onClick={e => {
										sectionDetailedTrack({
											category: GA_CATEGORY_DIALOG_BOX,
											action: 'Attachment Modal',
											label: 'Close ',
										})
										onClose(e)
									}}
									borderRadius='md'
									boxShadow='md'
									size='md'
								>
									{locale['Cancel']}
								</Button>
								<Button
									borderRadius='md'
									boxShadow='md'
									size='md'
									isDisabled={!fileList.length}
									variant='solid'
									colorScheme={localStorage.getItem('color')}
									bg={localStorage.getItem('color')}
									onClick={() => {
										sectionDetailedTrack({
											category: GA_CATEGORY_DIALOG_BOX,
											action: 'Attachment Modal',
											label: 'Upload',
										})
										onUpload(fileList)
									}}
								>
									{locale['Upload']}
								</Button>
							</ModalFooter>
						</VStack>
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default AttachmentModal
