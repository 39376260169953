/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Spinner, Flex, Button } from '@chakra-ui/react'
import React, { useRef, useCallback, useEffect, useState } from 'react'
import { useAuthContext } from '../../context/auth.context'
import { useSharedContext } from '../../context/shared.context'
import { useLoadTimelineList } from '../../hooks/activity.hooks'
import { sharedConst } from '../../utils/action.constant'
import TimelineComponents from './components'
import { TIMELINE_MESSAGE_TYPE } from './util'
/**
 * //TODO: Load new timeline on load more and put it in reverse order
 * //TODO: Listen to push notification and pull the latest timeline event again and append at last.
 * //TODO: On mount set unread count for an activity to 0
 *
 */

const Timeline = ({
	activity,
	filter = {
		value: TIMELINE_MESSAGE_TYPE.ALL,
		label: 'All',
	},
	onFormSelection,
	timelineReplyData = () => null,
	isUserHaveAccess = false,
}) => {
	const {
		state: { isRefreshTimeline, random },
		dispatch,
	} = useSharedContext()
	const messageEndRef = useRef(null)
	const msgDiv = useRef(null)
	const [data, setdata] = useState({})
	const [isLoading, setisLoading] = useState(false)
	const { mutate } = useLoadTimelineList()
	const {
		state: { authData },
	} = useAuthContext()

	const onScrollDown = () => {
		messageEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
	}

	const timelineScroll = id => {
		var elmnt = document.getElementById(id)
		if (elmnt) {
			elmnt.focus()
			elmnt.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const onSuccess = (res, isLatest) => {
		setisLoading(false)
		const { list, ...rest } = res
		if (!data || !Object.keys(data).length) {
			setdata({
				...rest,
				list: list.reverse(),
			})
			onScrollDown()
		} else {
			if (!!isLatest) {
				setdata({
					...rest,
					list: [...list.reverse()],
				})
				onScrollDown()
			} else {
				console.log({ data })
				setdata({
					...rest,
					list: [...list.reverse(), ...data.list],
				})
				//timelineScroll(`reply_item${data.list[0].timeline_transaction_id}`);
				msgDiv?.current?.scrollTo(0, 60)
			}
		}
	}

	const loadTimeline = useCallback(
		({ activity, start = 0, isLatest = false }) => {
			setisLoading(true)
			mutate(
				{
					activity_id: activity.activity_id,
					activity_type_category_id: activity.activity_type_category_id,
					file_unread_count: activity.asset_unread_updates_count,
					flag_previous: -2,
					page_start: !!isLatest ? 0 : start,
					page_limit: 20, //activity.asset_unread_updates_count load only unread details
					datetime_differential: 0,
					timeline_transaction_id: 0,
					sort_flag: 0,
					sort_order: 0,
				},
				{
					onSuccess: res => {
						onSuccess(res, isLatest)
					},
				}
			)
		}
	)

	useEffect(() => {
		if (
			(!data || (!Object.keys(data).length && !isLoading)) &&
			!!activity.activity_id
		) {
			loadTimeline({ activity })
		}
	}, [data])

	useEffect(() => {
		if (!!activity) {
			setdata({})
		}
	}, [activity.activity_id])

	useEffect(() => {
		if (!!isRefreshTimeline || random) {
			dispatch({ type: sharedConst.RESET_REFRESH_TIMELINE })
			loadTimeline({ activity, isLatest: true })
		}
	}, [isRefreshTimeline, random])

	const onScroll = event => {
		var element = event.target
		if (element.scrollTop === 0 && data.isMore) {
			loadTimeline({
				activity,
				start: data.page_start,
			})
		}
	}

	return (
		<Box
			ref={msgDiv}
			overflowY='scroll'
			h='100%'
			w='100%'
			px={1}
			onScroll={onScroll}
		>
			<Flex
				w='100%'
				justifyContent='center'
				alignItems='center'
				my={2}
				className={`timeline-section-scroll-view`}
			>
				{data && data.isMore && isLoading ? (
					// <Button
					//   id={`timeline-load-more-btn`}
					//   borderRadius="full"
					//   size="xs"
					//   px={2}
					//   variant="outline"
					//   color="brand.800"
					//   isLoading={isLoading}
					//   loadingText="Loading"
					//   onClick={() => {
					//     loadTimeline({
					//       activity,
					//       start: data.page_start,
					//     });
					//   }}
					// >
					//   Load more
					// </Button>
					<Spinner color='brand.800' />
				) : null}
			</Flex>

			{data && data.list && data.list
				? data.list.map((timelineItem, index) => (
						<TimelineComponents
							filter={filter.value}
							onFormSelection={onFormSelection}
							{...authData}
							key={timelineItem.timeline_transaction_id}
							timeline={timelineItem}
							timelineReplyData={e => {
								timelineReplyData(e)
								onScrollDown()
							}}
							timelineScroll={timelineScroll}
							isUserHaveAccess={isUserHaveAccess}
						/>
				  ))
				: null}
			<div ref={messageEndRef} />
		</Box>
	)
}

export default Timeline
