/* eslint-disable react-hooks/exhaustive-deps */
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	List,
	ListIcon,
	ListItem,
	Text,
	useAccordionItemState,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef } from 'react'
import { BsCircle } from 'react-icons/bs'
import { MdArrowDropDown, MdCheckCircle } from 'react-icons/md'
import { useLoadFileFilterList } from '../../hooks/shared.hooks'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// } from './../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const WorkflowTypeFilter = ({
	selectedFilter,
	onFilterChange,
	onQueueSelection,
}) => {
	const { mutate, isError, data } = useLoadFileFilterList()
	const loadFileFilterList = useCallback(() => {
		mutate({})
	})
	const {
		state: { locale },
	} = useLanguageContext()

	// const { isOpen, onOpen } = useAccordionItemState()

	useEffect(() => {
		loadFileFilterList()
	}, [])

	if (!data || !data.length || !!isError) {
		return null
	}

	return (
		<AccordionItem border='none' boxShadow='md' borderRadius='md' mb={2}>
			<AccordionButton
				_expanded={{
					bg: 'secondary',
					color: 'brand.900',
					borderRadius: 'md',
				}}
				_focus={{
					border: 'none',
				}}
			>
				<Box flex='1' textAlign='left'>
					<Text size='sm' fontWeight='500'>
						{locale['Workflow Search']}
					</Text>
				</Box>
				<AccordionIcon as={MdArrowDropDown} />
			</AccordionButton>
			<AccordionPanel
				pb={4}
				minH='200px'
				maxH='calc(100vh - 555px)'
				overflowY='auto'
			>
				<List spacing={3}>
					{(data || []).map((f, i) => {
						return (
							<div key={`${f.activity_type_id}-${i}`}>
								<ListItem
									cursor='pointer'
									display='flex'
									py={1}
									onClick={() => {
										// eventLabelTrack({
										// 	categoryId: 0,
										// 	actionId: 7,
										// 	label: `Selected ${f.activity_type_name}`,
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Home',
										// 	buttonName: `Workflow Search ${f.activity_type_name}`,
										// })
										onQueueSelection({})
										onFilterChange(f)
									}}
								>
									<ListIcon
										as={
											f.activity_type_id === selectedFilter.activity_type_id
												? MdCheckCircle
												: BsCircle
										}
										color={localStorage.getItem('color')}
										w={6}
										h={6}
									/>
									<Text fontSize='sm'>{f.activity_type_name}</Text>
								</ListItem>
								<Divider mt={1} />
							</div>
						)
					})}
				</List>
			</AccordionPanel>
		</AccordionItem>
	)
}

export default WorkflowTypeFilter
