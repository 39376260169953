export const TIMELINE_MESSAGE_TYPE = {
  ALL: 'ALL',
  DEFAULT: 'DEFAULT',
  COMMENTS: 'COMMENTS',
  MEDIA: 'MEDIA',
  DATA_MANAGEMENT: 'DATA_MANAGEMENT',
  SYSTEM_MESSAGE: 'SYSTEM_MESSAGE',
  INTEGRATION: 'INTEGRATION',
}

export const timelineOptions = locale => [
  {
    value: TIMELINE_MESSAGE_TYPE.ALL,
    label: locale['All'],
  },
  {
    value: TIMELINE_MESSAGE_TYPE.DEFAULT,
    label: locale['Default'],
  },
  {
    value: TIMELINE_MESSAGE_TYPE.COMMENTS,
    label: locale['Comments'],
  },
  {
    value: TIMELINE_MESSAGE_TYPE.MEDIA,
    label: locale['Media'],
  },
  {
    value: TIMELINE_MESSAGE_TYPE.DATA_MANAGEMENT,
    label: locale['Data management'],
  },
  {
    value: TIMELINE_MESSAGE_TYPE.SYSTEM_MESSAGE,
    label: locale['System message'],
  },
  {
    value: TIMELINE_MESSAGE_TYPE.INTEGRATION,
    label: locale['Integration updates'],
  },
]
